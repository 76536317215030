// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Splide from '@splidejs/splide'
// NOTE: CSS imported in app/javascript/stylesheets/application.scss

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.loadDualSlider()
    // this.loadVideoSliders()
    this.loadSinglePhotoSliders()
  }

  loadVideoSliders() {
    var elements = document.getElementsByClassName( 'splide-video' )
    Array.from(elements).forEach(element => {
      new Splide( element ).mount( { Video } )
    })
  }

  loadSinglePhotoSliders() {
    const config = {
      type     : 'loop',
      autoWidth: true,
      focus    : 'center',
      pagination: false,
      lazyLoad: 'nearby',
      gap: '1em',
      updateOnMove: true, // needed to apply CSS settings immediately (like brightness/opacity)
      padding: {
        right: '5rem',
        left : '5rem',
      },
    }
    var elements = document.getElementsByClassName( 'splide-single' )
    Array.from(elements).forEach(element => {
      new Splide( element, config ).mount()
    })
  }

  loadDualSlider() {
    var slider1 = document.getElementById('primary-slider')
    var slider2 = document.getElementById('secondary-slider')
    if (slider1 && slider2) {
      const config = {
        type     : 'loop',
        autoWidth: true,
        focus    : 'center',
        pagination: false,
        lazyLoad: 'nearby',
        gap: '1em',
        updateOnMove: true, // needed to apply CSS settings immediately (like brightness/opacity)
        padding: {
          right: '5rem',
          left : '5rem',
        },
      }
      // Create and mount the thumbnails slider.
      var secondarySlider = new Splide( slider2, {
        rewind      : true,
        fixedWidth  : 100,
        fixedHeight : 64,
        isNavigation: true,
        gap         : 10,
        focus       : 'center',
        pagination  : false,
        cover       : true,
        breakpoints : {
          '600': {
            fixedWidth  : 66,
            fixedHeight : 40,
          }
        }
      } ).mount()

      // Create the main slider.
      var primarySlider = new Splide( slider1, config )

      primarySlider.on( 'moved', ( newIndex, prevIndex, destIndex ) => {
        console.log(newIndex)
        console.log(prevIndex)
        console.log(destIndex)
        const prevSlide   = primarySlider.Components.Elements.getSlide( prevIndex );
        const newSlide    = primarySlider.Components.Elements.getSlide( newIndex );
        var prevImages = prevSlide.slide.getElementsByTagName("img")
        if (prevImages[0]) {
          //console.log(prevImages[0])
          prevImages[0].dataset.pinNopin = true
        }
        var newImages = newSlide.slide.getElementsByTagName("img")
        if (newImages[0]) {
          //console.log(newImages[0])
          delete newImages[0].dataset.pinNopin
        }
      } )

      /*
      primarySlider.on( 'active', ( slide ) => {
        console.log(slide)
        window.slide = slide
        if ((typeof slide.isActive === 'function') && (typeof slide.isVisible === 'function')) {
          if (slide.isActive() && slide.isVisible()) {
            console.log('active & visible', slide)
          }
        }
      } )
      */

      // Set the thumbnails slider as a sync target and then call mount.
      primarySlider.sync( secondarySlider ).mount()
    }
  }
}
