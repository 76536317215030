window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

export const trackingId = process.env.GOOGLE_ANALYTICS_TRACKING_ID || "UA-225785374-1"

// production: UA-9246633-1

document.addEventListener("turbolinks:load", (event) => {
  // Pass in this configuration to make this process
  // more reliable since this stuff is being changed
  // dynamically.
  gtag('config', trackingId, {
    page_location: event.data.url,
    page_path: event.srcElement.location.pathname,
    page_title: event.srcElement.title
  });
});

export default gtag
