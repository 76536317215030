import CookiesEuBanner from 'cookies-eu-banner'
import 'cookies-eu-banner/css/cookies-eu-banner.default.css'

document.addEventListener('DOMContentLoaded', () => {
  var cookiesBanner = new CookiesEuBanner(function () {
    // console.log("LOAD GA");
  }, true);

  function updateCookiesEuState() {
    var hasConsent = cookiesBanner.hasConsent()
    var state = '❔ Nor accepted nor rejected';

    if (hasConsent === true) {
      state = '✅ Accepted';
    } else if (hasConsent === false) {
      state = '❌ Rejected';
    }

    document.getElementById('cookies-eu-state').innerText = state;
  }

  let euState = document.getElementById('cookies-eu-state')
  if (euState) {
    updateCookiesEuState();
    setInterval(updateCookiesEuState, 100);

    // Prefer use addEventListener
    // For demo purpose I will use the CookiesEuBanner internal method
    cookiesBanner.addClickListener(document.getElementById('custom-accept'), function () {
      cookiesBanner.setConsent(true);
      cookiesBanner.removeBanner();
      updateCookiesEuState();
    });
    /*
    cookiesBanner.addClickListener(document.getElementById('custom-reject'), function () {
      cookiesBanner.setConsent(false);
      cookiesBanner.removeBanner();
      updateCookiesEuState();
    });
    */
    cookiesBanner.addClickListener(document.getElementById('custom-reset'), function () {
      cookiesBanner.deleteCookie(cookiesBanner.cookieName);
      window.location.reload();
    });
  }
})
