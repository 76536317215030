// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    sortable: { type: String, default: "no" }
  }
  static targets = ["add_item", "template"]

  connect() {
    this.element[this.identifier] = this
    var controllerThis = this

    const selectors = ['#vendor-select', '#alternate-vendor-id-select', '#guest-vendor-select']
    selectors.forEach(selector => {
      $(selector).select2({})
//      $(selector).on('select2:select', function (e) {
//        // this is redefined inside select2, so we need another
//        // reference to the controller's "this"
//        controllerThis.add_vendorable_from_select(e, selector)
//      })
    })

    if (this.sortableValue == "yes") {
      const options = {
        group: 'shared',
        animation: 150,
        onStart: function () {
          // Save order before sort in case of an AJAX error when trying to update
          // position in backend. We can revert back to original list.
          this._currentOrder = this.toArray()
        },
        onEnd: function (event) {
          var nestedFields = Array.from(document.querySelectorAll('.nested-fields'))
          nestedFields.forEach((item, index) => {
            var position = item.getElementsByClassName("sortable-position")[0]
            position.value = index + 1
          })
        } 
      }

      // The handle class; e.g.,
      //   data-handle-class="handle"
      if (this.element.dataset.handleClass) {
        options.handle = `.${this.element.dataset.handleClass}`
      }
      this.sortable = Sortable.create(this.element, options)
    }
  }

  add_association(event) {
    event.preventDefault()  
    var newId = new Date().valueOf()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, newId)
    // console.log("adding content:")
    // console.log(content)
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    
    // Cross-Controller Coordination With Events
    // https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
    //
    // For example, setup Uppy on file inputs after the HTML as been inserted
    this.dispatch("add_association", { detail: { id: newId } })

    // make sure all datepicker fields are working
    $('.datepicker').pickadate({
      format: 'm-d-yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenName: true
    })
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
    item.classList.add("d-none")
    item.classList.remove("d-flex")
  }

  add_vendorable_from_select(event) {
    event.preventDefault()  
    var id = event.target.dataset.elementId
    // console.log(id)
    var selector = document.getElementById(id)
    // console.log(selector)
    var text = selector.options[selector.selectedIndex].text
    // console.log(text)
    // console.log(selector.value)
    var parts = selector.value.split('/')
    var modelType = parts[3]
    var modelId = parts[4]
    // console.log(modelType)
    // console.log(modelId)

    var alternateVendorTypeSelect = document.getElementById('alternate-vendor-id-select')
    var alternateVendorTypeId = null
    if (alternateVendorTypeSelect) {
      alternateVendorTypeId = alternateVendorTypeSelect.value
    }

    var url = null
    switch (modelType) {
      case 'Vendor':
        url = `/admin/vendors/${ modelId }`
        break
      case 'Venue':
        url = `/admin/venues/${ modelId }`
        break
      case 'GuestVendor':
        url = `/admin/guest_vendors/${ modelId }`
        break
    }
    var link = null
    if (url) {
      link = `<a target="_blank" href="${ url }">${ text }</a>`
    } else {
      link = text
    }

    if (this.sortableValue == "yes") {
      var newPosition = 1
      var lastPositionElement = Array.from(document.querySelectorAll('.sortable-position')).pop()
      if (lastPositionElement) {
        newPosition = parseInt(lastPositionElement.value) + 1
        // console.log("newPosition: " + newPosition)
      }
    }

    var content = this.templateTarget.innerHTML.
      replace(/TEMPLATE_RECORD/g, new Date().valueOf()).
      replace(/TEMPLATE_DATA_NAME/g, link).
      replace(/TEMPLATE_DATA_VENDORABLE_ID/g, modelId).
      replace(/TEMPLATE_DATA_VENDORABLE_TYPE/g, modelType).
      replace(/TEMPLATE_DATA_ALTERNATE_VENDOR_TYPE_ID/g, alternateVendorTypeId).
      replace(/TEMPLATE_DATA_POSITION/g, newPosition)
    if (modelType == "Vendor" || modelType == "Venue") {
      content = content.replace(/TEMPLATE_DATA_BADGE_DISPLAY/g, 'd-none')
    }
    // console.log("adding content:")
    // console.log(content)
    
    // insert add the end of this controller's element
    this.element.insertAdjacentHTML('beforeend', content)

    // reset select
    // selector.selectedIndex = -1
    $(selector).val(null).trigger("change")
    $(alternateVendorTypeSelect).val(null).trigger("change")
  }
}
