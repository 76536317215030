// Import core
const Core = require('@uppy/core')

// Import the plugins
const FileInput = require('@uppy/file-input')
const Informer = require('@uppy/informer')
const ProgressBar = require('@uppy/progress-bar')
const ThumbnailGenerator = require('@uppy/thumbnail-generator')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')
const AwsS3 = require('@uppy/aws-s3')
const AwsS3Multipart = require('@uppy/aws-s3-multipart')


// And their styles (for UI plugins)
// With webpack and `style-loader`, you can require them like this:
require('@uppy/core/dist/style.css')
require('@uppy/file-input/dist/style.css')
require('@uppy/dashboard/dist/style.css')
require('@uppy/progress-bar/dist/style.css')

const randomstring = require('randomstring')

const singleFileUpload = (fileInput) => {
  const imagePreview = document.getElementById(fileInput.dataset.previewElement)
  const formGroup    = fileInput.parentNode

  formGroup.removeChild(fileInput)

  const uppy = fileUpload(fileInput)

  // After already uploaded files have been reattached, put
  // autoProceed back on
  uppy.setOptions({ autoProceed: true })

  uppy
    .use(FileInput, {
      target: formGroup,
      locale: { strings: { chooseFiles: 'Choose file' } },
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: imagePreview.parentNode,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })

  uppy.on('upload-success', (file, response) => {
    const fileData = uploadedFileData(file, response, fileInput)

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    const hiddenInput = document.getElementById(fileInput.dataset.uploadResultElement)
    hiddenInput.value = fileData
  })

  uppy.on('thumbnail:generated', (file, preview) => {
    imagePreview.src = preview
  })
}

// Attributes that can be set on server-side in erb template
//
// hidden_field_name_start (optional): we use Album, PhotoAlbum, StylizedPhotoAlbum, etc.
//   for Album model name in associations
//   default: 'photo_album_attributes'
//
// upload_server (required): method defined in ApplicationHelper
//
// Example:
//
//   data: {
//     album_field_name: 'album_attributes',
//     upload_server: upload_server,
//   }
const multipleFileUpload = (fileInput) => {
  const formGroup = fileInput.parentNode

  const uppy = fileUpload(fileInput)

  let dashboardOpts = {
    target: formGroup,
    inline: true,
    height: 470,
    replaceTargetContent: true,
    showRemoveButtonAfterComplete: true,
    locale: {
      strings: {
        uploadComplete: 'Images selected'
      }
    }
  }
  if (fileInput.dataset.dashboardNote) {
    dashboardOpts.note = fileInput.dataset.dashboardNote
  }
  // console.log(dashboardOpts)
  uppy.use(Dashboard, dashboardOpts)

  // reattach files after form submission errors
  document.querySelectorAll('.uploaded-images').forEach((item, index) => {
    // console.log(item.id)
    // console.log(item.dataset.url)
    // console.log(item.dataset.name)
    return fetch(item.dataset.url)
    .then((response) => response.blob()) // returns a Blob
    .then((blob) => {
      // grab the file immediately
      const uppyFileId = uppy.addFile({
        name: item.dataset.name,
        type: blob.type,
        data: blob
      })

      // change the original hidden field's id so that we can remove
      // this hidden field if the user deciced to remove the image from
      // the uppy dashboard
      item.id = uppyFileId

      const fileFromUppy = uppy.getFile(uppyFileId);
      uppy.emit('upload-started', fileFromUppy);
      uppy.emit('upload-progress', fileFromUppy, {
        bytesUploaded: blob.size,
        bytesTotal: blob.size,
      });
      uppy.emit('upload-success', fileFromUppy, 'reattached');
      // console.log(fileFromUppy)
    })
  })
  // After already uploaded files have been reattached, put
  // autoProceed back on
  uppy.setOptions({ autoProceed: true })

  uppy.on('upload-success', (file, response) => {
    // console.log(response)
    if (response == "reattached") {
      return
    }
    const hiddenField = document.createElement('input')

    let albumFieldName = fileInput.dataset.albumFieldName
    if (!albumFieldName) {
      albumFieldName = 'photo_album_attributes'
    }
    //hiddenField.name = `${albumFieldName}[${randomstring.generate()}][image]`
    hiddenField.name = `${albumFieldName}[][image]`

    hiddenField.type = 'hidden'
    var jsonData = uploadedFileData(file, response, fileInput)
    hiddenField.value = jsonData
    // attach the file id as the input's id here so that we can manipulate it later
    hiddenField.id = file.id

    document.querySelector('form').appendChild(hiddenField)
  })
  uppy.on('file-removed', (file, reason) => {
    // console.log(reason)
    // console.log(file)
    if (reason === 'removed-by-user') {
      // file.id added as id to hidden input in uppy.on('upload-success' ...
      var el = document.getElementById(file.id)
      el.remove()
    }
  })
}

const fileUpload = (fileInput) => {

  let opts = {
    id: fileInput.id,
    autoProceed: false,
    restrictions: {
      allowedFileTypes: fileInput.accept.split(','),
    },
  }
  let maxNumberOfFiles = parseInt(fileInput.dataset.maxNumberOfFiles)
  if (Number.isInteger(maxNumberOfFiles) && maxNumberOfFiles > 0) {
    opts.restrictions.maxNumberOfFiles = maxNumberOfFiles
  }
  let maxFileSize = parseInt(fileInput.dataset.maxFileSize)
  if (Number.isInteger(maxFileSize) && maxFileSize > 0) {
    opts.restrictions.maxFileSize = maxFileSize
  }

  // console.log(opts.restrictions)
  const uppy = Core(opts)

  if (fileInput.dataset.uploadServer == 's3') {
    uppy.use(AwsS3, {
      companionUrl: '/', // will call Shrine's presign endpoint mounted on `/s3/params`
    })
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    uppy.use(AwsS3Multipart, {
      companionUrl: '/' // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    })
  } else {
    uppy.use(XHRUpload, {
      endpoint: '/upload', // Shrine's upload endpoint
    })
  }

  return uppy
}

const uploadedFileData = (file, response, fileInput) => {
  if (fileInput.dataset.uploadServer == 's3') {
    const id = file.meta['key'].match(/^cache\/(.+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id))
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id))
  } else {
    return JSON.stringify(response.body)
  }
}

// constructs uploaded file data in the format that Shrine expects
const fileData = (file, id) => ({
  id: id,
  storage: 'cache',
  metadata: {
    size:      file.size,
    filename:  file.name,
    mime_type: file.type,
  }
})

export { singleFileUpload, multipleFileUpload }
