import "trix"

document.addEventListener("turbolinks:load", () => {
  addEventListener("trix-initialize", function(e) {
    const file_tools = document.querySelector(".trix-button-group--file-tools")
    if (file_tools) {
      file_tools.remove()
    }
  })
  addEventListener("trix-file-accept", function(e) {
    e.preventDefault()
  })

  // Make links open in new window
  // See: // https://github.com/basecamp/trix/issues/55#issuecomment-655384053
  $(".trix-content a").click(function(e) {
    $(this).attr("target","_blank");
  });
})
