// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $("#zipSearchButton").on('click', function() {
      $.fancybox.close($("#hiddenSearch"))
    })

    $("#fancybox-hidden-search-zip").on('click', function() {
      $.fancybox.open($("#hiddenSearch"), {
        onInit: function( instance, current ) {
          $('#zipSearchContainer').removeClass('display_none')
          $('#filterSearchContainer').addClass('display_none')
        }
      })
    })
    $("#fancybox-hidden-search").on('click', function() {
      $.fancybox.open($("#hiddenSearch"), {
        onInit: function( instance, current ) {
          $('#filterSearchContainer').removeClass('display_none')
          $('#zipSearchContainer').addClass('display_none')
        }
      })
    })
  }
}
