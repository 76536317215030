// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// NOTE: CSS located here: app/javascript/stylesheets/admin.scss
import { Controller } from "stimulus"
import { showToast } from 'src/seotericToast'

export default class extends Controller {

  connect() {
    window.toasts = this.application.getControllerForElementAndIdentifier(document.getElementById("body"), "toasts")

    this.element.querySelectorAll('input[type=file]').forEach(fileInput => {
      if (fileInput.multiple) {
        multipleFileUpload(fileInput)
      } else {
        singleFileUpload(fileInput)
      }
    })
  }

  onDestroySuccess() {
    const li = event.target.closest("li")
    li.remove()
    window.toasts.success("Successfully destroyed photo.")

    const countElement = document.getElementById("photos-count")
    if (countElement) {
      // DEBUG
      // window.countElement = countElement
      // console.log("countElement", countElement)
      
      let count = parseInt(countElement.innerHTML, 10)
      if (!isNaN(count)) {
        countElement.innerHTML = count - 1
      }
    }
  }

  onPostError(event) {
    window.toasts.success("Unable to destroy photo.")
  }

  removePhoto(event) {
    const elementName = event.currentTarget.dataset.elementName
    // console.log("elementName", elementName)
    const removeId = `remove_${elementName}`
    const removeElement = document.getElementById(removeId)
    const previewId = `preview_${elementName}`
    const previewElement = document.getElementById(previewId)
    previewElement.classList.add("d-none")
    removeElement.value = 1
  }

  // Coming from Cross-Controller Coordination With Events; i.e.,
  // events are dispatched here
  // https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
  addUppyToFileFields({ detail: { id } }) {
    // console.log(id)
    const element = document.getElementById(id)
    element.querySelectorAll('input[type=file]').forEach(fileInput => {
      if (fileInput.multiple) {
        multipleFileUpload(fileInput)
      } else {
        singleFileUpload(fileInput)
      }
    })
  }
}

// Import core
const Core = require('@uppy/core')

// Import the plugins
const FileInput = require('@uppy/file-input')
const Informer = require('@uppy/informer')
const ProgressBar = require('@uppy/progress-bar')
const ThumbnailGenerator = require('@uppy/thumbnail-generator')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')
const AwsS3 = require('@uppy/aws-s3')
const AwsS3Multipart = require('@uppy/aws-s3-multipart')

const randomstring = require('randomstring')

const singleFileUpload = (fileInput) => {
  const elementName = fileInput.dataset.elementName

  // Default to the '/upload' endpoint, but it can be overridden
  // on the file input element 
  let shrineEndpoint = '/upload'
  if (fileInput.dataset.shrineEndpoint) {
    // console.log("fileInput.dataset.shrineEndpoint", fileInput.dataset.shrineEndpoint)
    shrineEndpoint = fileInput.dataset.shrineEndpoint
  }

  const removeId = `remove_${elementName}`
  const removeElement = document.getElementById(removeId)
  const previewId = `preview_${elementName}`
  const previewElement = document.getElementById(previewId)
  const imageId = `image_${elementName}`
  const imageElement = document.getElementById(imageId)
  const progressId = `progress_${elementName}`
  const progressElement = document.getElementById(progressId)
  const uploadResultId = `upload_result_${elementName}`
  const uploadResultElement = document.getElementById(uploadResultId)

  const formGroup = fileInput.parentNode

  formGroup.removeChild(fileInput)

  const uppy = fileUpload(fileInput, shrineEndpoint)

  uppy
    .use(FileInput, {
      target: formGroup,
      locale: { strings: { chooseFiles: 'Choose file' } },
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: progressElement,
      hideAfterFinish: false
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })

  uppy.on('upload-success', (file, response) => {
    // Try to display the thumbnail preview if this is a PDF upload
    if (response.body.previewUrl) {
      // DEBUG
      // console.log("response.body.previewUrl", response.body.previewUrl)
      // console.log("imageElement.id", imageElement.id)
      // console.log("imageElement.src", imageElement.src)

      imageElement.src = response.body.previewUrl
      previewElement.classList.remove("d-none")
    }

    const fileData = uploadedFileData(file, response, fileInput)

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    uploadResultElement.value = fileData

    // Make sure that the hidden field that notifies Shrine to delete an
    // image is not set to "1". That is, the value is set to "1" when the
    // user removes an exisiting image. This will set it back to "0" if the
    // user add a different image right after removing the original image.
    if (removeElement) {
      removeElement.value = 0
    }
  })

  uppy.on('thumbnail:generated', (file, preview) => {
    imageElement.src = preview
    previewElement.classList.remove("d-none")
  })
}

const multipleFileUpload = (fileInput) => {
  const formGroup = fileInput.parentNode

  const uppy = fileUpload(fileInput)

  uppy
    .use(Dashboard, {
      target: formGroup,
      inline: true,
      height: 300,
      replaceTargetContent: true,
    })

  uppy.on('upload-success', (file, response) => {
    const hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    hiddenField.name = `photo_album_attributes[photos_attributes][${randomstring.generate()}][image]`
    hiddenField.value = uploadedFileData(file, response, fileInput)

    document.querySelector('form').appendChild(hiddenField)

    const form = formGroup.closest('form')
    const formData = new FormData(form)
    formData.append("image", uploadedFileData(file, response, fileInput))

    Rails.ajax({
      url: form.action,
      dataType: "html",
      type: 'PATCH',
      data: formData,
      success: function(response) {
        const container = document.querySelector("#album-photo-container")
        container.insertAdjacentHTML('beforeend', response.html);

        // DEBUG
        // console.log("response", response)
        // window.response = response

        const countElement = document.getElementById("photos-count")
        if (countElement && response.photoCount && Number.isInteger(response.photoCount)) {
          countElement.innerHTML = response.photoCount
        }
      },
      error: function(response, status, xhr) {
        // DEBUG
        // window.response = response
        // console.log("response", response)
        // window.status = status
        // console.log("status", status)
        // window.xhr = xhr
        // console.log("xhr", xhr)

        let message = "ERROR: Something went wrong, and we have been notified."

        if ((xhr.status < 500) && response) {
          message = response
        }

        uppy.removeFile(file.id)
        uppy.info({
          message: message,
          // details: 'File couldn’t be uploaded because we were unable to generate thumbnails for this image.',
        }, 'error', 60000)
        // console.log(file.id)
        // window.toasts.error("Unable to process image")
        // console.error('error => ', response)
      }
    })
  })
  //  uppy.on('complete', (result) => {
  //    console.log('successful files:', result.successful)
  //    console.log('failed files:', result.failed)
  //  })
  //  uppy.on('error', (error) => {
  //    console.error(error.stack);
  //  });
  //  uppy.on('upload-error', (file, error, response) => {
  //    console.log('error with file:', file.id);
  //    console.log('error message:', error);
  //  });
  //  uppy.on('restriction-failed', (file, error) => {
  //    // do some customized logic like showing system notice to users
  //    console.log('error with file:', file.id);
  //    console.log('error message:', error);
  //  });
}

const fileUpload = (fileInput, localEndpoint = '/upload') => {
  const uppy = Core({
    id: fileInput.id,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: fileInput.accept.split(','),
    },
  })

  if (fileInput.dataset.uploadServer == 's3') {
    uppy.use(AwsS3, {
      companionUrl: '/', // will call Shrine's presign endpoint mounted on `/s3/params`
    })
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    uppy.use(AwsS3Multipart, {
      companionUrl: '/' // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    })
  } else {
    uppy.use(XHRUpload, {
      endpoint: localEndpoint, // Shrine's upload endpoint
    })
  }

  return uppy
}

const uploadedFileData = (file, response, fileInput) => {
  if (fileInput.dataset.uploadServer == 's3') {
    const id = file.meta['key'].match(/^cache\/(.+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id))
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id))
  } else {
    return JSON.stringify(response.body)
  }
}

// constructs uploaded file data in the format that Shrine expects
const fileData = (file, id) => ({
  id: id,
  storage: 'cache',
  metadata: {
    size:      file.size,
    filename:  file.name,
    mime_type: file.type,
  }
})
