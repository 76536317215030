// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import "src/trixCustom"
require("@rails/actiontext")

import "../src/add-eu-banner"

window.Rails = Rails

import gtag from 'src/analytics'
window.gtag = gtag

import jquery from 'jquery'
window.$ = window.jquery = jquery
window.jQuery = $
require("@fancyapps/fancybox")

require('../src/pinterest')

// Fonts & Icons
// NOTE: Don't turn on the javascript stuff since it's too hard
// to deal with svg icons and javascript manipulation
//import fontawesome from '@fortawesome/fontawesome-pro/js/all.js'
//fontawesome.config = { autoReplaceSvg: false }
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

// Change the config to fix the flicker
config.mutateApproach = 'sync'

import { frontendIconsFactory } from 'src/frontendIconsFactory'
const frontendIcons = frontendIconsFactory(config, library, dom)

dom.watch()

require("../stylesheets/application.scss")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

import { createPopper } from '@popperjs/core'
window.createPopper = createPopper
import tippy from 'tippy.js'
window.tippy = tippy

document.addEventListener("turbolinks:load", function() {
  const tippyTemplate = document.getElementById('search-menu-template')
  if (tippyTemplate) {
    tippyTemplate.style.display = 'block'
    const tippyElement = document.getElementById('search-menu')
    if (typeof(tippyElement) != 'undefined' && tippyElement != null) {
      tippy(tippyElement, {
        appendTo: document.body,
        allowHTML: true,
        maxWidth: 'none',
        hideOnClick: true,
        content: tippyTemplate,
        interactive: true,
        arrow: true,
        //animateFill: false,
        offset: [0, 7],
        placement: "bottom",
        animation: "fade",
        theme: "light-border",
        trigger: "click",
        zIndex: 999999
      })
    }

    const tippyCityTemplate = document.getElementById('search-city-template')
    tippyCityTemplate.style.display = 'block'
    const tippyCityElement = document.getElementById('search-city')
    if (typeof(tippyCityElement) != 'undefined' && tippyCityElement != null) {
      tippy(tippyCityElement, {
        appendTo: document.body,
        maxWidth: 'none',
        content: tippyCityTemplate,
        hideOnClick: true,
        allowHTML: true,
        interactive: true,
        arrow: true,
        //animateFill: false,
        offset: [0, 7],
        placement: "bottom",
        animation: "fade",
        theme: "light-border",
        trigger: "click",
        zIndex: 999999,
      })
    }
  }
})


import { singleFileUpload, multipleFileUpload } from 'src/fileUpload'

// Use 'DOMContentLoaded' event if not using Turbolinks
document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('input[type=file]').forEach(fileInput => {
    if (fileInput.multiple) {
      multipleFileUpload(fileInput)
    } else {
      singleFileUpload(fileInput)
    }
  })
})
