// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { showToast } from 'src/seotericToast'

export default class extends Controller {
  // static values = { container: String }
  static targets = [ "container" ]

  connect() {
    window.toasts = this.application.getControllerForElementAndIdentifier(document.getElementById("body"), "toasts")

    // adds ability to access the top element where controller is defined using this.element
    // this.element[this.identifier] = this
    // console.log('connected to ajax-refresh-html')
  }

  onPostSuccess(event) {
    this.updateHTML(event)
    window.toasts.success("Saved successfully")
  }

  onPostError(event) {
    this.updateHTML(event)
    window.toasts.error("Unable to save")
  }

  updateHTML(event) {
    const query = this.data.get("query")
    const container = document.querySelector(query)
    let [data, status, xhr] = event.detail
    container.innerHTML = JSON.parse(xhr.responseText).html
  }

  replaceContainer(event) {
    let [data, status, xhr] = event.detail
    this.containerTarget.innerHTML = JSON.parse(xhr.responseText).html
  }
}
