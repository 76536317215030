// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["imageModal"]

  connect() {
    $('#myCarousel').on('slide.bs.carousel', function () {
      // console.log("sliding")
    })
  }

  onNextSuccess(event) {
    // console.log('onNextSuccess')
    let [data, status, xhr] = event.detail
    document.querySelector("#carouselExample").innerHTML = xhr.response
  }

  onPostSuccess(event) {
    // console.log('onPostSuccess')
    let [data, status, xhr] = event.detail
    document.querySelector("#photoModal").innerHTML = xhr.response
    $('#photoModal').modal('show')
  }
}
