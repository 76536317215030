// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const ratingStars = [...document.getElementsByClassName("rating__star")]
    this.executeRating(ratingStars)
  }


  executeRating(stars) {
    const prefixActive = "fas"
    const prefixInactive = "far"

    const starsLength = stars.length
    let i
    const hiddenField = document.getElementById('review_rating')
    const currentRating = parseInt(hiddenField.value)

    if (Number.isInteger(currentRating) && currentRating > 0) {
      for (var k = 0; k < currentRating; k++) {
        stars[k].setAttribute('data-prefix', prefixActive)
      }
    }

    stars.map((star) => {
      star.onclick = () => {
        // The "star" element is just the span container. Have to find
        // the actual icon which has been converted to an SVG by
        // Fontawesome and chang4e the data-prefix attribute on the given
        // SVG element.
        let svg = star.querySelector('[data-fa-i2svg]')
        let i = stars.indexOf(star)
        const hiddenField = document.getElementById('review_rating')
        hiddenField.value = i + 1

        // If star clicked has non-solid (regular) star icon, then just
        // make it a solid star and work backwards from there making
        // all stars to the left of it solid
        if (svg.getAttribute('data-prefix') === prefixInactive) {
          for (i; i >= 0; --i) {
            let s = stars[i].querySelector('[data-fa-i2svg]')
            s.setAttribute('data-prefix', prefixActive)
          }
        // Else, the star is already solid so make sure all stars to the
        // the right of it are non-solid stars
        } else {
          for (var j = i + 1; j < starsLength; ++j) {
            let s = stars[j].querySelector('[data-fa-i2svg]')
            s.setAttribute('data-prefix', prefixInactive)
          }
        }
      }
    })
  }
}
