// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="admin--maps">
//   <div class="field">
//     <%= form.label :latitude %>
//     <%= form.text_field :latitude, class: 'form-control', data: { target: "maps.latitude" } %>
//   </div>
// </div>

import { Controller} from "stimulus"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude", "placeFormattedAddress", "placeId"]

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }

  initializeMap() {
    this.map()
    this.setPlace()
    this.marker()
    this.autocomplete()
  }

  map() {
    if(this._map == undefined) {
      let lat = this.latitudeTarget.value || 39.5
      let lon = this.longitudeTarget.value || -98.35
      let zoom = this.latitudeTarget.value == "" ? 4 : 15

      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(lat, lon),
        zoom: zoom
      })
    }

    return this._map
  }

  setPlace() {
    const map = this.map()
    const placeId = this.placeIdTarget.value
    if (placeId != "") {
      const request = {
        placeId: placeId,
        fields: ["name", "formatted_address", "place_id", "geometry"],
      }
      const service = new google.maps.places.PlacesService(map)
      service.getDetails(request, function (place, status) {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place &&
          place.geometry &&
          place.geometry.location
        ) {
          const marker = new google.maps.Marker({
            map,
            title: place.name,
            position: place.geometry.location,
          })
          map.fitBounds(place.geometry.viewport)
          map.setCenter(place.geometry.location)
          marker.setPosition(place.geometry.location)
          marker.setVisible(true)
        }
      })
    }
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0)
      })
      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
    }
    return this._marker
  }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
      this._autocomplete.bindTo('bounds', this.map())
      this._autocomplete.setFields(['formatted_address', 'place_id', 'address_components', 'geometry', 'icon', 'name'])
      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    }
    return this._autocomplete
  }

  locationChanged() {
    let place = this.autocomplete().getPlace()

    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    //console.log("place", place)
    //console.log("place.place_id", place.place_id)
    //console.log("place.name", place.name)
    //console.log("place.formatted_address", place.formatted_address)

    this.map().fitBounds(place.geometry.viewport)
    this.map().setCenter(place.geometry.location)
    this.marker().setPosition(place.geometry.location)
    this.marker().setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
    this.placeFormattedAddressTarget.value = place.formatted_address
    this.placeIdTarget.value = place.place_id
  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }
}
