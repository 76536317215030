// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="admin--dashboard-charts">
//   <h1 data-target="admin--dashboard-charts.output"></h1>
// </div>

import Fetch from "fetch-rails"

import { Controller } from "stimulus"

var charts = {}

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.showUsersChart()
  }

  showUsersChart() {
    showAnalyticsLineChart(
      '/admin/dashboard/users.json',
      'users-chart'
    )
  }

  showSessionsChart(event) {
    showAnalyticsLineChart(
      '/admin/dashboard/sessions.json',
      'sessions-chart'
    )
  }

  showBounceRateChart() {
    showAnalyticsLineChart(
      '/admin/dashboard/bounce_rate.json',
      'bounce-rate-chart'
    )
  }

  showSessionDurationChart() {
    showAnalyticsLineChart(
      '/admin/dashboard/session_duration.json',
      'session-duration-chart'
    )
  }

}

function showAnalyticsLineChart(url, chartId) {
  if (charts[chartId] === undefined) {
    Fetch.json(url, {}, {})
      .then( function( data ){
        var ctx = document.getElementById(chartId).getContext('2d')
        var config = analyticsLineChartConfig(data)
        var chart = new Chart(ctx, config)
        charts[chartId] = chart
      }).catch( function( error ){
        // FIXME - do something with error
        console.log( error ) // error in json
      })
  }
  return charts[chartId]
}

function analyticsLineChartConfig(data) {
  let config = {
    type: 'line',
    data: data,
    options: {
      legend: {
        display: false,
      },
      hover: {
        intersect: false,
      },
      plugins: {},
      tooltips: {
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex === 0
        },
        mode: 'index',
        intersect: false,
        enabled: false,
        callbacks: {
          label: function(tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].label
          }
        },
        custom: function(tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip')

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.id = 'chartjs-tooltip'
            tooltipEl.innerHTML = '<div></div>'
            document.body.appendChild(tooltipEl)
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform')
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign)
          } else {
            tooltipEl.classList.add('no-transform')
          }

          function getBody(bodyItem) {
            return bodyItem.lines
          }

          // Set Text
          if (tooltipModel.body) {
            var bodyLines = tooltipModel.body.map(getBody)

            var innerHtml = ''

            bodyLines.forEach(function(body, i) {
              innerHtml += body
            })

            tooltipEl.innerHTML = innerHtml
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect()

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
          tooltipEl.style.pointerEvents = 'none'
        },
      },
    },
  }
  return config
}
