import { Controller } from "stimulus"
import { Notyf } from 'notyf'
import { showToast } from 'src/seotericToast'

export default class extends Controller {
  static targets = [ "name", "form" ]

  toggle() {
    if (this.isClickInTargets(event)) {
      if (this.formTarget.classList.contains("d-none")) {
        this.nameTarget.classList.add("d-none")
        this.formTarget.classList.remove("d-none")
      }
    } else {
      if (this.nameTarget.classList.contains("d-none")) {
        this.nameTarget.classList.remove("d-none")
        this.formTarget.classList.add("d-none")
      }
    }
  }

  isClickInTargets(event) {
    const status = event && (this.nameTarget.contains(event.target) || this.formTarget.contains(event.target))
    return status
  }

  onSuccessAddToEndOfNode(event) {
    // The target is the form that submitted this event.
    // Just reset the form for creating new list items;
    // no need to replace it with a JSON html response
    event.target.reset()

    // find the node that contains the collection
    // and add JSON response html value to end of collection; i.e.; add another
    // widget to the end of the list of widgets
    const selector = event.target.dataset.selector
    const node = document.querySelector(selector)
    let [data, status, xhr] = event.detail
    const additionalHtml = JSON.parse(xhr.responseText).html
    node.innerHTML += additionalHtml

    // show success toast message
    const toastMessage = event.target.dataset.successToast
    showToast("success", toastMessage)

    // Clean up errors
    this.removeById('error_explanation')
    this.removeById('error_video_url')
    const errorItems = [...document.getElementsByClassName('field_with_errors')]
    errorItems.forEach(errorItem => {
      errorItem.classList.remove('field_with_errors')
    })
  }

  removeById(id) {
    const element = document.querySelector('#' + id)
    if (element) {
      element.remove()
    }
  }

  onErrorReplaceSelf(event) {
    // replace the target form with the html value
    // from the JSON response; on the server side, we
    // want the same form to have the errors added and re-displayed
    // to the user; the collection container does not change
    let [data, status, xhr] = event.detail
    
    const jsonData = JSON.parse(xhr.responseText)
    const replacementHtml = jsonData.html
    event.target.outerHTML = replacementHtml

    if (jsonData.error) {
      showToast("error", jsonData.error)
    }

    // show the error toast message
    const toastMessage = event.target.dataset.errorToast
    if (toastMessage) {
      showToast("error", toastMessage)
    }
  }

  onPostSuccess(event) {
    this.updateHTML(event, 'success', true)
    showToast("success", "Saved successfully")
  }

  onPostError(event) {
    this.updateHTML(event, 'error')
    showToast("error", "Unable to save")
  }

  onCreateSuccess(event) {
    console.log(event.target)
    this.updateHTML(event, 'success', true)
    showToast("success", "Saved successfully")
  }

  onCreateError(event) {
    console.log(event.target)
    this.updateHTML(event, 'error')
    showToast("error", "Unable to save")
  }

  onDestroySuccess(event) {
    const query = event.target.dataset.removequery
    const container = document.querySelector(query)
    container.remove()
    showToast("success", "Deleted successfully")
  }

  onDestroyError(event) {
    showToast("error", "Unable to delete. Please try again later.")
  }

  updateHTML(event, queryType, replace=false) {
    const query = this.data.get(`${queryType}-query`)
    const container = document.querySelector(query)
    let [data, status, xhr] = event.detail
    const html = JSON.parse(xhr.responseText).html
    if (replace == true) {
      container.outerHTML = html
    } else {
      container.innerHTML = html
    }
  }
}
