// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element[this.identifier] = this 
  }

  fillInput(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const source = event.target
    const input = document.getElementById(source.dataset.targetid)

    input.value = source.dataset.value

    if (source.dataset.companytype) {
      input.dataset.companytype = source.dataset.companytype
    }
    if (source.dataset.categorytype) {
      input.dataset.categorytype = source.dataset.categorytype
    }
    if (source.dataset.slug) {
      input.dataset.slug = source.dataset.slug
    }

    // hide the Tippy.js dropdowns
    input._tippy.hide()

    if (source.dataset.categorytype == "cities") {
      this.refreshTippyContent(source.dataset.ajaxurl, "search-menu")
    } else if (source.dataset.categorytype == "venue_styles" || source.dataset.categorytype == "vendor_types") {
      this.refreshTippyContent(source.dataset.ajaxurl, "search-city")
    }
  }

  refreshTippyContent(url, tippyElementId) {
    Rails.ajax({
      url: url,
      type: 'GET',
      success: function(response) {
        const toReplace = document.getElementById(tippyElementId)
        toReplace._tippy.setContent(response.html)
      },
      error: function(response) {
        console.log('error')
      }
    })
  }

  submit(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const button = event.target
    const form = button.closest('form')
    const companyInput = document.getElementById("search-menu")
    const cityInput = document.getElementById("search-city")

    var url = ""
    const companySelected = companyInput.dataset.companytype && companyInput.dataset.categorytype && companyInput.dataset.slug
    const citySelected = cityInput.dataset.categorytype && cityInput.dataset.slug
    const bothSelected = companySelected && citySelected

    // build a friendly URL if dropdown input has been selected
    if (companySelected) {
      if (companyInput.dataset.categorytype == "vendor_types") {
        if (companyInput.dataset.slug == "all_vendors") {
          url = `${url}/vendors`
        } else {
          url = `${url}/vendors/types/${companyInput.dataset.slug}`
        }
      } else if (companyInput.dataset.categorytype == "venue_styles") {
        if (companyInput.dataset.slug == "all_venues") {
          url = `${url}/venues`
        } else {
          url = `${url}/venues/styles/${companyInput.dataset.slug}`
        }
      }
    }
    if (citySelected) {
      url = `${url}/cities/${cityInput.dataset.slug}`
    }

    // if both fields are selcted from dropdowns, then we can just
    // got to a "friendly" generated URL
    //
    // if only 1 dropdown has been selected, then we change the form
    // action and honor the user's typed-in input in the other input (if any)
    //
    // lastly, if no dropdown input has been selected, then the form is submitted
    // as is with only user typed input
    if (bothSelected) {
      if (url != "") {
        window.location.href = url
      }
    } else {
      if (companySelected || citySelected) {
        form.action = url
      }
      form.submit()
    }
  }

  // Fired from change event
  clearInput(event) {
    const input = event.target

    // Only clear the input if it's been filled from the dropdowns and not
    // from user input
    if (input.dataset.companytype) {
      input.dataset.companytype = null
    }
    if (input.dataset.categorytype) {
      input.dataset.categorytype = null
    }

    if (input.dataset.clearurl) {
      if (input.id == "search-city") {
        this.refreshTippyContent(input.dataset.clearurl, "search-menu")
      } else if (input.id == 'search-menu') {
        this.refreshTippyContent(input.dataset.clearurl, "search-city")
      }
    }

    if (input.dataset.slug) {
      input.dataset.slug = null
      // Also clear the value which only happens if value comes from
      // links in dropdown and not user input
      input.value = null
    }

  }
}
