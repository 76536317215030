// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this
    console.log("drag no ajax")

    this.sortable = Sortable.create(this.element, {
      handle: '.handle', // handle's class
      group: 'shared',
      animation: 150,
      onStart: function () {
        // Save order before sort in case of an AJAX error when trying to update
        // position in backend. We can revert back to original list.
        this._currentOrder = this.toArray()
      },
      onEnd: function (event) {
        document.querySelectorAll('.list-group-item').forEach((item, index) => {
          var positionInput = document.getElementById(item.dataset.hiddenId)
        })
      } 
    })
  }
}
