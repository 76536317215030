document.addEventListener("turbolinks:load", function() {
  (function(d){
    delete window["PIN_"+~~((new Date).getTime()/864e5)]

    var f = d.getElementsByTagName('SCRIPT')[0], p = d.createElement('SCRIPT')
    p.id = 'pinterest-js'
    p.type = 'text/javascript'
    p.async = true
    p.src = '//assets.pinterest.com/js/pinit.js'
    p.setAttribute("data-pin-hover", "true")
    p.setAttribute("data-pin-round", "true")
    p.setAttribute("data-pin-tall", "32px")
    f.parentNode.insertBefore(p, f)
  }(document))
})


/*
document.addEventListener("turbolinks:load", function() {
  
  jquery.getScript({
  type: "GET",
  url: "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5fce7ac2990dd17f",
  dataType: "script",
  async: true,
  
  })
})

<script data-pin-hover="true" data-pin-round="true" data-pin-tall="32px" src="//assets.pinterest.com/js/pinit.js"></script>
<!-- Go to www.addthis.com/dashboard to customize your tools -->
<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5fce7ac2990dd17f"></script>
*/
