// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "clickable", "template" ]

  connect() {
    tippy(this.clickableTarget, {
      appendTo: document.body,
      maxWidth: 'none',
      content: this.templateTarget.innerHTML,
      allowHTML: true,
      interactive: true,
      arrow: true,
      //animateFill: false,
      offset: [0, 7],
      placement: "bottom",
      animation: "fade",
      theme: "light-border",
      trigger: "click",
      zIndex: 999999,
    })
  }
}
