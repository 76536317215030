import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "moreButton", "lessButton", "direct"]
  truncateClass = "truncate"
  lineHeightFloat = 1.2
  lineHeightMultiplier = 6.0

  connect() {
    if (!(this.data.get("direct") == "true")) {
      // Ability to override default line height (em units)
      const floatString1 = this.data.get("lineHeightEm")
      if (typeof floatString1 !== null) {
        this.lineHeightFloat = parseFloat(floatString1)
      }

      const floatString2 = this.data.get("lineHeightMultiplier")
      if (typeof floatString2 !== null) {
        this.lineHeightMultiplier = parseFloat(floatString2)
      }

      this.contentTarget.style.height = `${this.lineHeightFloat * this.lineHeightMultiplier}em`

      // Sets the opaque style on the last line
      const styleElem = document.head.appendChild(document.createElement("style"))
      const height = `${this.lineHeightFloat}em`
      styleElem.innerHTML = '.truncate:after { height:' + height + '; }'
    }
  }

  showMoreDirect() {
    const elements = this.contentTarget.getElementsByClassName("hide-target")
    Array.from(elements).forEach(element => {
      this.show(element)
    })
    this.hide(this.moreButtonTarget)
    this.show(this.lessButtonTarget)
  }
  showLessDirect() {
    const elements = this.contentTarget.getElementsByClassName("hide-target")
    Array.from(elements).forEach(element => {
      this.hide(element)
    })
    this.hide(this.lessButtonTarget)
    this.show(this.moreButtonTarget)
  }

  showMore() {
    this.untruncate(this.contentTarget)
    this.hide(this.moreButtonTarget)
    this.show(this.lessButtonTarget)
  }

  showLess() {
    this.truncate(this.contentTarget)
    this.hide(this.lessButtonTarget)
    this.show(this.moreButtonTarget)
  }

  untruncate(target) {
    this.contentTarget.style.height = null 
    this.contentTarget.classList.remove(this.truncateClass)
  }

  truncate(target) {
    this.contentTarget.style.height = `${this.lineHeightFloat * this.lineHeightMultiplier}em`
    this.contentTarget.classList.add(this.truncateClass)
  }

  show(target) {
    target.classList.remove("hide")
  }

  hide(target) {
    target.classList.add("hide")
  }
}
