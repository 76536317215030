// This example controller works with specially annotated HTML like:
//
// <div data-controller="toggle" data-toggle-class="hidden">
//   <button data-action="toggle#toggle">Toggle</button>
//   <div data-target="toggle.content">
//     Some special content
//   </div>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  toggle(event) {
    event.preventDefault()

    const button = event.currentTarget
    const buttonClass = button.dataset.buttonClass
    const changeText = button.dataset.changeButtonText != "no"

    // if expanded, close it 
    if (button.classList.contains(buttonClass)) {
      button.classList.remove(buttonClass)
      if (changeText) {
        button.innerText = 'Show More';
      }
      button.setAttribute('aria-expanded', false);
    // if closed, expand it
    } else {
      button.classList.add(buttonClass)
      if (changeText) {
        button.innerText = 'Show Less';
      }
      button.setAttribute('aria-expanded', true);
    }
    this.contentTargets.forEach((t) => t.classList.toggle(this.data.get("class")));
  }
}
