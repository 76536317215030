import { faFacebookSquare as fabFaFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faInstagram as fabFaInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPinterestSquare as fabFaPinterestSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare as fabFaTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faArrowRotateLeft as falFaArrowRotateLeft } from '@fortawesome/pro-light-svg-icons'
import { faCheck as falFaCheck } from '@fortawesome/pro-light-svg-icons'
import { faCircleXmark as falFaCircleXmark } from '@fortawesome/pro-light-svg-icons'
import { faEnvelope as falFaEnvelope } from '@fortawesome/pro-light-svg-icons'
import { faEye as falFaEye } from '@fortawesome/pro-light-svg-icons'
import { faFloppyDisk as falFaFloppyDisk } from '@fortawesome/pro-light-svg-icons'
import { faPhoneFlip as falFaPhoneFlip } from '@fortawesome/pro-light-svg-icons'
import { faTrashCan as falFaTrashCan } from '@fortawesome/pro-light-svg-icons'
import { faXmark as falFaXmark } from '@fortawesome/pro-light-svg-icons'
import { faListUl as farFaListUl } from '@fortawesome/pro-regular-svg-icons'
import { faMagnifyingGlass as farFaMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { faStar as farFaStar } from '@fortawesome/pro-regular-svg-icons'
import { faStar as fasFaStar } from '@fortawesome/pro-solid-svg-icons'
import { faTableCells as farFaTableCells } from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight as fasFaChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { faChevronUp as fasFaChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { faLocationPin as fasFaLocationPin } from '@fortawesome/pro-solid-svg-icons'
import { faPhone as fasFaPhone } from '@fortawesome/pro-solid-svg-icons'
import { faEnvelope as fasFaEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { faFax as fasFaFax } from '@fortawesome/pro-solid-svg-icons'
import { faClock as fasFaClock } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as fasFaCircle } from '@fortawesome/pro-solid-svg-icons'
import { faTriangleExclamation as falFaTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { faCircleCheck as fasFaCircleCheck } from '@fortawesome/pro-solid-svg-icons'

function frontendIconsFactory(config, library, dom) {
  library.add(fabFaFacebookSquare, fabFaInstagram, fabFaPinterestSquare, fabFaTwitterSquare, falFaArrowRotateLeft, falFaCheck, falFaCircleXmark, falFaEnvelope, falFaEye, falFaFloppyDisk, falFaPhoneFlip, falFaTrashCan, falFaXmark, farFaListUl, farFaMagnifyingGlass, farFaStar, fasFaStar, farFaTableCells, fasFaChevronRight, fasFaChevronUp, fasFaLocationPin, fasFaPhone, fasFaEnvelope, fasFaFax, fasFaClock, fasFaCircle, falFaTriangleExclamation, fasFaCircleCheck)
}

export { frontendIconsFactory }
