import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "icon", "count" ]
  static values = {
    reloadOnClick: String,
    removeFavoritesFromPageOnClick: String,
    domId: String
  }

  connect() {
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail

    // update the count
    // var count = document.getElementById("favorites-count")
    // count.innerHTML = xhr.response

    // toggle the icon
    this.iconTarget.checked = !this.iconTarget.checked


    if (this.reloadOnClickValue == "true") {
      location.reload();
    }

    if (this.removeFavoritesFromPageOnClickValue == "yes") {
      console.log(this.domIdValue)
      if (this.domIdValue) {
        //console.log("domId", document.getElementById(this.domIdValue))
        "domId", document.getElementById(this.domIdValue).remove()
      }
    }
  }
  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  handleChange(event) {
    const input = event.target;
    switch (input.id) {
      case 'lead_name':
      case 'lead_phone':
      case 'lead_email':
      case 'date':
      case 'lead_number_of_guests':
      case 'lead_message':
        const name = 'form_' + input.id;
        const value = input.value;
        this.setCookie(name, value, 60);
        break;
      default:
        break;
    }
  }
}
