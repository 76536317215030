// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Fetch from "fetch-rails"

import { Controller } from "stimulus"

export default class extends Controller {

  /*
  initialize() {
    // FIXME: clean this up
    const e = document.querySelector("#leadRequestSent")
    if (typeof(e) != 'undefined' && e != null) {
      const v = e.dataset.single
      if ( v == "true") {
        document.addEventListener('turbolinks:load', () => {
          $.fancybox.open({
            src: '#leadPopForm'
          });
        })
      } else {
          $.fancybox.close({
            src: '#leadPopForm'
          });
      }
    }
  }
  */

  connect() {
    const companyCount = this.setCompanyCount()
    if (companyCount > 0) {
      this.showBox()
    }
  }

  onPostSuccess(event) {
    // hideSpinner();
    gtag('event', 'click', {'event_category': 'Cart Lead Form', 'event_label': 'Cart Lead Form' })

    const lead_form_name = event.target.querySelector('input[name="lead[name]"]').value;
    const lead_form_email = event.target.querySelector('input[name="lead[email]"]').value;
    const lead_form_phone = event.target.querySelector('input[name="lead[phone]"]').value;
    const lead_form_wedding_date = event.target.querySelector('input[name="lead[wedding_date]"]').value;
    const lead_form_number_of_guests = event.target.querySelector('input[name="lead[number_of_guests]"]').value;
    const lead_form_message = event.target.querySelector('input[name="lead[message]"]').value;
    this.setCookie('form_lead_name', lead_form_name, 60);
    this.setCookie('form_lead_email', lead_form_email, 60);
    this.setCookie('form_lead_phone', lead_form_phone, 60);
    this.setCookie('form_date', lead_form_wedding_date, 60);
    this.setCookie('form_lead_number_of_guests', lead_form_number_of_guests, 60);
    this.setCookie('form_lead_message', lead_form_message, 60);
    // GA4 Tracking
    let multipleCompanyDiv =document.querySelector("#leadPopFormCompanies");
    let companyDivs = multipleCompanyDiv.querySelectorAll(".requestCompanyItem");
    let items = [];
    companyDivs.forEach(function(companyDiv) {
      let item_id = companyDiv.getAttribute('data-company-id');
      let item_name = companyDiv.getAttribute('data-company-name');
      let item_variant = companyDiv.getAttribute('data-company-type');
      let item = {
        item_id: item_id,
        item_name: item_name,
        item_variant: item_variant,
        quantity: 1
      }
      items.push(item);
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "multiple-select",
      ecommerce: {
        form_origin: "multiple",
        items: items
      } 
    });
    let [data, status, xhr] = event.detail
    const leadPopForm = document.querySelector("#leadPopForm")
    leadPopForm.innerHTML = JSON.parse(xhr.responseText).html
    leadPopForm.scrollTo({
      top: 0,
      behavior: 'instant'
    })
    this.clearCompanies();
  }

  onPostError(event) {
    // hideSpinner();
    let [data, status, xhr] = event.detail
    const lead_form_name = event.target.querySelector('input[name="lead[name]"]').value;
    const lead_form_email = event.target.querySelector('input[name="lead[email]"]').value;
    const lead_form_phone = event.target.querySelector('input[name="lead[phone]"]').value;
    const lead_form_wedding_date = event.target.querySelector('input[name="lead[wedding_date]"]').value;
    const lead_form_number_of_guests = event.target.querySelector('input[name="lead[number_of_guests]"]').value;
    const lead_form_message = event.target.querySelector('input[name="lead[message]"]').value;
    this.setCookie('form_lead_name', lead_form_name, 60);
    this.setCookie('form_lead_email', lead_form_email, 60);
    this.setCookie('form_lead_phone', lead_form_phone, 60);
    this.setCookie('form_date', lead_form_wedding_date, 60);
    this.setCookie('form_lead_number_of_guests', lead_form_number_of_guests, 60);
    this.setCookie('form_lead_message', lead_form_message, 60);
    const leadPopForm = document.querySelector("#leadPopForm")
    leadPopForm.innerHTML = JSON.parse(xhr.responseText).html
    leadPopForm.scrollTo({
      top: 0,
      behavior: 'instant'
    })

    if(data.show_checkbox_recaptcha){
      const recaptchaKey = document.querySelector('#multi-form #recaptcha-key').getAttribute('data-key');

      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.render(document.querySelector('#multi-form .g-recaptcha'), {sitekey: recaptchaKey})
      } else {
        console.log('Recaptcha not loaded')
      }
    }
  }

  setCompanyCount() {
    const companyCount = document.querySelectorAll('#requestInfoCompanies .imgContainer').length
    this.data.set("companyCount", companyCount)
    return companyCount
  }

  hideBox() {
    const box = document.querySelector("#requestInfoBox")
    box.style.display = 'none'
  }

  showBox() {
    const box = document.querySelector("#requestInfoBox")
    box.style.display = 'block'
  }

  toggleBox() {
    const companyCount = this.setCompanyCount()
    if (companyCount > 0) {
      this.showBox()
    } else {
      this.hideBox()
    }
  }

  toggleModal() {
    const companyCount = this.setCompanyCount()
    if (companyCount < 2) {
      $.fancybox.close()
    }
  }

  /*
   * - AJAX call to add company to cookie
   * - AJAX method handles opening box
   */
  createCompany(event) {
    const url = event.target.dataset.url
    let data = null
    let divNode = document.getElementById("search_filters_form")
    if (divNode) {
      let form = document.createElement("form")
      let inputNodes = divNode.getElementsByTagName('INPUT')
      Array.from(inputNodes).forEach(element => {
        form.appendChild(element.cloneNode())
      })
      data = new FormData(form)
    }
    this.requestInfoAjax('POST', url, data)
  }

  /*
   * - AJAX call to delete company from cookie
   * - AJAX method handles closing box if no companies listed
   * - makes sure that company listing checkbox is unchecked
   */
  deleteCompany(event) {
    const url = event.target.dataset.url
    this.requestInfoAjax('DELETE', url)
  }

  deleteAndUncheckCompany(event) {
    event.preventDefault()
    // Reverse the add-to-cart functionality:
    // - Delete the company from the session on the server
    // - Hide the "Added" message on the company listing
    // - Show the "Request Pricing" button
    const companyId = parseInt(event.target.dataset.companyId)

    if (companyId) {
      // console.log("companyId", companyId)
      const hiddenId = `company-added-${companyId}`
      // console.log("hiddenId", hiddenId)
      const hiddenDiv = document.getElementById(hiddenId)
      if (hiddenDiv) {
        // console.log("hiddenDiv", hiddenDiv)
        hiddenDiv.style.display = "none"
        // console.log("hiddenDiv", hiddenDiv)
      }
      const buttonId = `company-${companyId}-button`
      const button = document.getElementById(buttonId)
      if (button) {
        button.style.display = "block"
      }
      this.deleteCompany(event)
    }
  }

  deleteSelfAndCompany(event) {
    const div = event.target.closest("div.imgContainer")
    div.remove()
    this.deleteCompany(event)
    this.toggleModal()
  }

  clearAllCompanies() {
    const url = '/request_info'
    // Removes companies from cookie and closes the request box
    this.requestInfoAjax('DELETE', url)

    // Show all "Request Pricing" buttons
    var requestPricingButtons = document.querySelectorAll(".request-pricing-button")
    for (var i = 0; i< requestPricingButtons.length; i++) {
      if (requestPricingButtons[i].style.display == "none") {
        requestPricingButtons[i].style.display = "block"
      }
    }
    // Hide all "Added" divs
    var cartAddedDivs = document.querySelectorAll(".cartAdded")
    for (var i = 0; i< cartAddedDivs.length; i++) {
      if (cartAddedDivs[i].style.display == "block") {
        cartAddedDivs[i].style.display = "none"
      }
    }
    $.fancybox.close({
      src: '#leadPopForm'
    });
  }
  clearCompanies() {
    const url = '/request_info'
    // Removes companies from cookie and closes the request box
    this.requestInfoAjax('DELETE', url)

    // Show all "Request Pricing" buttons
    var requestPricingButtons = document.querySelectorAll(".request-pricing-button")
    for (var i = 0; i< requestPricingButtons.length; i++) {
      if (requestPricingButtons[i].style.display == "none") {
        requestPricingButtons[i].style.display = "block"
      }
    }
    // Hide all "Added" divs
    var cartAddedDivs = document.querySelectorAll(".cartAdded")
    for (var i = 0; i< cartAddedDivs.length; i++) {
      if (cartAddedDivs[i].style.display == "block") {
        cartAddedDivs[i].style.display = "none"
      }
    }
  }
  activateRequestInfoBox(event) {
    event.preventDefault()
    window.item = event.target
    if (event.target.tagName == 'A') {
      this.createCompany(event)
      event.target.style.display = "none"
      let companyId = item.dataset.companyId
      // console.log("companyId", companyId)
      let hiddenId = "company-added-" + companyId
      // console.log("hiddenId", hiddenId)
      let hiddenDiv = document.getElementById(hiddenId)
      // console.log("hiddenDiv", hiddenDiv)
      hiddenDiv.style.display = "block"
      // console.log("hiddenDiv", hiddenDiv)
    } else {
      this.deleteCompany(event)
    }
  }

  openLeadPopForm(event) {
    const url = event.target.dataset.url

    Rails.ajax({
      url: url,
      type: 'POST',
      dataType: 'html',
      success: function(response) {
        // DEBUG
        // console.log(response) // response in json
        
        const toFill = document.querySelector("#leadPopForm")
        toFill.innerHTML = response.html
      },
      error: function(response) {
        console.error('error => ', response)
      }
    })

    $.fancybox.open({
      src: '#leadPopForm',
      toolbar: true,
      buttons: ["close"]
		});
  }

  openSingleRequestForm(event) {
    let companyId = event.target.dataset.companyid;
    let companyType = event.target.dataset.companytype;
    let companyUrl = event.target.dataset.companyurl;
    let urlParts = companyUrl.split("/");
    let url = event.target.dataset.url;
    companyUrl = window.location.origin + companyUrl;
    Rails.ajax({
      url: url,
      type: 'POST',
      data: new URLSearchParams({company_type: companyType, company_id: companyId, company_url: companyUrl, url: urlParts[2] }),
      dataType: 'html',
      success: function(response) {
        const toFill = document.querySelector("#singleLeadPopForm")
        toFill.innerHTML = response.html;
        $.fancybox.open({
          src: '#singleLeadPopForm',
          toolbar: true,
          buttons: ["close"]
        });
      },
      error: function(response) {
        console.error('error => ', response)
      }
    })
  }

  singleAddToList(event) {
    let companyId = event.target.dataset.companyid;
    let addToListButtonId = "company-" + companyId + "-button";
    let button = document.getElementById(addToListButtonId);
    if(button) {
      button.click();
      $.fancybox.close({
        src: '#singleLeadPopForm'
      });
    }
  }
  postSuccess(event) {
    gtag('event', 'click', {'event_category': 'Single Lead Form', 'event_label': 'Single Lead Form' })
    const lead_form_name = event.target.querySelector('input[name="lead[name]"]').value;
    const lead_form_email = event.target.querySelector('input[name="lead[email]"]').value;
    const lead_form_phone = event.target.querySelector('input[name="lead[phone]"]').value;
    const lead_form_wedding_date = event.target.querySelector('input[name="lead[wedding_date]"]').value;
    const lead_form_number_of_guests = event.target.querySelector('input[name="lead[number_of_guests]"]').value;
    const lead_form_message = event.target.querySelector('input[name="lead[message]"]').value;
    this.setCookie('form_lead_name', lead_form_name, 60);
    this.setCookie('form_lead_email', lead_form_email, 60);
    this.setCookie('form_lead_phone', lead_form_phone, 60);
    this.setCookie('form_date', lead_form_wedding_date, 60);
    this.setCookie('form_lead_number_of_guests', lead_form_number_of_guests, 60);
    this.setCookie('form_lead_message', lead_form_message, 60);
    // GA4 Tracking
    let singleLeadForm = document.querySelector("#create-single-lead-form");
    let companyId = singleLeadForm.querySelector("#company_id").value;
    let companyType = singleLeadForm.querySelector("#company_type").value.toLowerCase();
    let requestUrl = singleLeadForm.querySelector("#request_url").value;
    requestUrl = requestUrl.split('/');
    let companyName = requestUrl[requestUrl.length - 1];
    companyName = companyName.replace(/-/g, ' ');
    var capitalizedCompanyName = companyName.replace(/\b\w/g, function(char) {
      return char.toUpperCase();
    });
    let eventName = "wedding_" + companyType + "s_submit";

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        form_origin: "individual",
        items: [
          {
            item_id:  companyId,
            item_name: capitalizedCompanyName,
            item_variant: companyType,
            quantity: 1
          }
        ]
      } 
    });
    $.fancybox.close({
      src: '#singleLeadPopForm'
    });

    let [data, status, xhr] = event.detail
    const leadPopForm = document.querySelector("#leadPopForm")
    let company_id = JSON.parse(xhr.responseText).company_id;
    let requestButtonId = '#company-' + company_id + '-request-button';
    let requested = '#company-requested-' + company_id;
    let requestButton = document.querySelector(requestButtonId);
    let requestedDiv = document.querySelector(requested);
    requestButton.style.display = 'none';
    requestedDiv.style.display = 'block';
    leadPopForm.innerHTML = JSON.parse(xhr.responseText).html
    this.element.innerHTML = JSON.parse(xhr.responseText).cleared
    $.fancybox.open({
			src: '#leadPopForm'
		});
  }
  postError(event) {
    let [data, status, xhr] = event.detail
    const lead_form_name = event.target.querySelector('input[name="lead[name]"]').value;
    const lead_form_email = event.target.querySelector('input[name="lead[email]"]').value;
    const lead_form_phone = event.target.querySelector('input[name="lead[phone]"]').value;
    const lead_form_wedding_date = event.target.querySelector('input[name="lead[wedding_date]"]').value;
    const lead_form_number_of_guests = event.target.querySelector('input[name="lead[number_of_guests]"]').value;
    const lead_form_message = event.target.querySelector('input[name="lead[message]"]').value;
    this.setCookie('form_lead_name', lead_form_name, 360);
    this.setCookie('form_lead_email', lead_form_email, 360);
    this.setCookie('form_lead_phone', lead_form_phone, 360);
    this.setCookie('form_date', lead_form_wedding_date, 360);
    this.setCookie('form_lead_number_of_guests', lead_form_number_of_guests, 360);
    this.setCookie('form_lead_message', lead_form_message, 360);

    // replace the contents of the div with controller with response
    this.element.innerHTML = JSON.parse(xhr.responseText).html

    if(data.show_checkbox_recaptcha){
      const recaptchaKey = this.element.querySelector('#single-form #recaptcha-key').getAttribute('data-key');

      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.render(this.element.querySelector('#single-form .g-recaptcha'), {sitekey: recaptchaKey})
      } else {
        console.log('Recaptcha not loaded')
      }
    }
  }
  addToList(event) {
    event.preventDefault();
    let favElement = document.querySelector('[data-controller="favorites"]');
    if(favElement) {
      favElement.click();
      this.activateRequestInfoBox(event);
    }
  }
  getCookie(name) {
    const nameEQ = name + "=";
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i];
      while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  }
  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  handleChange(event) {
    const input = event.target;
    switch (input.id) {
      case 'lead_name':
      case 'lead_phone':
      case 'lead_email':
      case 'date':
      case 'lead_number_of_guests':
      case 'lead_message':
        const name = 'form_' + input.id;
        const value = input.value;
        this.setCookie(name, value, 60);
        break;
      default:
        break;
    }
  }
  requestInfoAjax(type, url, data=null) {
    let me = this

    Rails.ajax({
      url: url,
      type: type,
      data: data,
      dataType: 'html',
      success: function(response) {
        // DEBUG
        // console.log(response) // response in json
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.html, 'text/html');
        // Select and count the div elements with the specific classes
        const divCount = doc.querySelectorAll('div.imgContainer.center.requestCompanyItem').length;
        const leadCount = document.querySelector("#leads-count");
        const leadCountMobile = document.querySelector("#leads-count-mobile");
        if(divCount > 0) {
          if(leadCount) {
            leadCount.innerText = divCount;
          }
          if(leadCountMobile) {
            leadCountMobile.innerText = divCount;
          }
        } else {
          leadCount.innerText = "0"
          leadCountMobile.innerText = "0"
        }
        let companyContent = document.querySelector("#requestInfoCompanies")
        companyContent.innerHTML = response.html
        me.toggleBox()
      },
      error: function(response) {
        console.error('error => ', response)
      }
    })
  }
}
