// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Fetch from "fetch-rails"

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // this.element now points to element that controller is defined on
    this.element[this.identifier] = this 
  }

  onPostSuccess(event) {
    // hideSpinner();
    gtag('event', 'click', {'event_category': 'Single Lead Form', 'event_label': 'Single Lead Form' })
    const lead_form_name = event.target.querySelector('input[name="lead[name]"]').value;
    const lead_form_email = event.target.querySelector('input[name="lead[email]"]').value;
    const lead_form_phone = event.target.querySelector('input[name="lead[phone]"]').value;
    const lead_form_wedding_date = event.target.querySelector('input[name="lead[wedding_date]"]').value;
    const lead_form_number_of_guests = event.target.querySelector('input[name="lead[number_of_guests]"]').value;
    const lead_form_message = event.target.querySelector('input[name="lead[message]"]').value;
    this.setCookie('form_lead_name', lead_form_name, 60);
    this.setCookie('form_lead_email', lead_form_email, 60);
    this.setCookie('form_lead_phone', lead_form_phone, 60);
    this.setCookie('form_date', lead_form_wedding_date, 60);
    this.setCookie('form_lead_number_of_guests', lead_form_number_of_guests, 60);
    this.setCookie('form_lead_message', lead_form_message, 60);
    // GA4 Tracking
    let singleLeadForm = document.querySelector("#create-single-lead-form");
    let companyId = singleLeadForm.querySelector("#company_id").value;
    let companyType = singleLeadForm.querySelector("#company_type").value.toLowerCase();
    let requestUrl = singleLeadForm.querySelector("#request_url").value;
    requestUrl = requestUrl.split('/');
    let companyName = requestUrl[requestUrl.length - 1];
    companyName = companyName.replace(/-/g, ' ');
    var capitalizedCompanyName = companyName.replace(/\b\w/g, function(char) {
      return char.toUpperCase();
    });
    let eventName = "wedding_" + companyType + "s_submit";

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        form_origin: "individual",
        items: [
          {
            item_id:  companyId,
            item_name: capitalizedCompanyName,
            item_variant: companyType,
            quantity: 1
          }
        ]
      } 
    });

    let [data, status, xhr] = event.detail
    const leadPopForm = document.querySelector("#leadPopForm")
    leadPopForm.innerHTML = JSON.parse(xhr.responseText).html
    this.element.innerHTML = JSON.parse(xhr.responseText).cleared
    $.fancybox.open({
			src: '#leadPopForm'
		});

    /*
    document.getElementById('create-single-lead-form').reset()
    // Clean up errors
    this.removeById('finder-lead-form-errors')
    this.removeByClass('label_with_errors')
    this.unwrap('.field_with_errors')
    */

  }

  onPostError(event) {
    // hideSpinner();
    let [data, status, xhr] = event.detail
    const lead_form_name = event.target.querySelector('input[name="lead[name]"]').value;
    const lead_form_email = event.target.querySelector('input[name="lead[email]"]').value;
    const lead_form_phone = event.target.querySelector('input[name="lead[phone]"]').value;
    const lead_form_wedding_date = event.target.querySelector('input[name="lead[wedding_date]"]').value;
    const lead_form_number_of_guests = event.target.querySelector('input[name="lead[number_of_guests]"]').value;
    const lead_form_message = event.target.querySelector('input[name="lead[message]"]').value;
    this.setCookie('form_lead_name', lead_form_name, 60);
    this.setCookie('form_lead_email', lead_form_email, 60);
    this.setCookie('form_lead_phone', lead_form_phone, 60);
    this.setCookie('form_date', lead_form_wedding_date, 60);
    this.setCookie('form_lead_number_of_guests', lead_form_number_of_guests, 60);
    this.setCookie('form_lead_message', lead_form_message, 60);

    // replace the contents of the div with controller with response
    this.element.innerHTML = JSON.parse(xhr.responseText).html

    if(data.show_checkbox_recaptcha){
      const recaptchaKey = this.element.querySelector('#single-form #recaptcha-key').getAttribute('data-key');

      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.render(this.element.querySelector('#single-form .g-recaptcha'), {sitekey: recaptchaKey})
      } else {
        console.log('Recaptcha not loaded')
      }
    }
  }
  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  handleChange(event) {
    const input = event.target;
    switch (input.id) {
      case 'lead_name':
      case 'lead_phone':
      case 'lead_email':
      case 'date':
      case 'lead_number_of_guests':
      case 'lead_message':
        const name = 'form_' + input.id;
        const value = input.value;
        this.setCookie(name, value, 60);
        break;
      default:
        break;
    }
  }
  removeById(id) {
    const element = document.querySelector('#' + id)
    if (element) {
      element.remove()
    }
  }
  removeByClass(name) {
    document.querySelectorAll('.' + name).forEach(element => {
      element.remove()
    })
  }

  unwrap(selector) {
    var nodelist = document.querySelectorAll(selector);
    Array.prototype.forEach.call(nodelist, function(item,i){
      item.outerHTML = item.innerHTML; // or item.innerText if you want to remove all inner html tags 
    })
  }
}
