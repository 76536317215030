// Examples to show success and error toasts:
//
//   this.showToast("success", "Saved successfully")
//   this.showToast("error", "Unable to save")
//

import { Notyf } from 'notyf'

const showToasts = (toasts, notyf=null) => {
  if (notyf == null) {
    notyf = new Notyf()
  }
  toasts.forEach(toast => {
    showToast(toast.kind, toast.message, notyf)
  })
}

const showToast = (kind, message, notyf=null) => {
  if (notyf == null) {
    notyf = new Notyf()
  }
  if (kind == "success") {
    notyf.success({
      message: message,
      duration: 2000,
      position: {
        x: 'right',
        y: 'top',
      }
    })
  } else if (kind == "error") {
    notyf.error({
      message: message,
      duration: 0,
      dismissible: true,
      icon: {
        className: "fas fa-exclamation-triangle",
        tagName: "i",
        color: "white",
      },
      position: {
        x: 'right',
        y: 'top',
      }
    })
  }
}

export { showToast, showToasts }
