// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"
import { showToast } from 'src/seotericToast'

export default class extends Controller {
  connect() {
    const options = {
      group: 'shared',
      animation: 150,
      onStart: function () {
        // Save order before sort in case of an AJAX error when trying to update
        // position in backend. We can revert back to original list.
        this._currentOrder = this.toArray()
      },
      onEnd: function (event) {
        /*
        console.log("event.to", event.to)
        console.log("event.from", event.from)
        console.log("event.oldIndex", event.oldIndex)
        console.log("event.newIndex", event.newIndex)
        console.log("event.oldDraggableIndex", event.oldDraggableIndex)
        console.log("event.newDraggableIndex", event.newDraggableIndex)
        console.log("toArray", this.toArray())
        */
        const data = new FormData()
        data.append("position", event.newDraggableIndex + 1)
        // NOTE: event.item is from the sortablejs event and not the stimulusjs event
        // so we use ".item" rather than ".target"
        const url = event.item.dataset.dragUrl

        // Need to define these here rather than directly in AJAX call due to scoping
        const obj = this
        const originalOrder = obj._currentOrder

        Rails.ajax({
          url: url,
          type: 'PATCH',
          data: data,
          success: function(response) {
            showToast("success", "Sort order saved successfully", notyf)
          },
          error: function(response) {
            // Revert the list to its original order if the AJAX save call has an error
            obj.sort(originalOrder)
            showToast("error", "Unable to save sort order. Please try again later.", notyf)
          }
        })
      } 
    }

    // The handle class; e.g.,
    //   data-handle-class="handle"
    if (this.element.dataset.handleClass) {
      options.handle = `.${this.element.dataset.handleClass}`
    }
    this.sortable = Sortable.create(this.element, options)
  }
}
