// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    show: String
  }

  connect() {
    // console.log("@favorites_leads_sent", this.showValue)
    if (this.showValue == "yes") {
      $.fancybox.open({
        src: '#leadPopForm',
        toolbar: true,
        buttons: ["close"]
      })
    }
  }

  disconnect() {
    $.fancybox.close()
  }
}
