// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { Notyf } from 'notyf'

export default class extends Controller {

  connect() {
    const toasts = []
    document.querySelectorAll('.toast_message').forEach(toastDiv => {
      toasts.push({
        kind: toastDiv.dataset.toasttype,
        message: toastDiv.innerHTML,
      })
    })
    this.showToasts(toasts)
  }

  error(message) {
    this.showToast("error", message)
  }

  success(message) {
    this.showToast("success", message)
  }

  showToasts(toasts) {
    toasts.forEach(toast => {
      this.showToast(toast.kind, toast.message)
    })
  }

  showToast(kind, message) {
    if (window.notyf == null) {
      window.notyf = new Notyf()
    }
    if (kind == "success" || kind == "notice") {
      window.notyf.success({
        message: message,
        duration: 2000,
        position: {
          x: 'right',
          y: 'top',
        }
      })
    } else if (kind == "error") {
      window.notyf.error({
        message: message,
        duration: 0,
        dismissible: true,
        icon: {
          className: "fas fa-exclamation-triangle",
          tagName: "i",
          color: "white",
        },
        position: {
          x: 'right',
          y: 'top',
        }
      })
    }
  }
}
