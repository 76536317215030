import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "form" ]

  connect(element) {
    // this.element now points to element that controller is defined on
    // this.element[this.identifier] = this
  }

 	replace (event) {
    event.preventDefault()
    const [, , xhr] = event.detail
    const html1 = document.getElementById("finder_results")
    const html2 = document.getElementById("finder-form-mobile")
    var json = JSON.parse(xhr.response)
    html1.outerHTML = json.html1
    html2.outerHTML = json.html2
  }

 	replace2 (event) {
    event.preventDefault()
    console.log(event.target.dataset)
    console.log(event.target.dataset.containerId)

    const [, , xhr] = event.detail
    const container = document.getElementById(event.target.dataset.containerId)

    container.outerHTML = xhr.response
  }

  replace3(event) {
    return
    const url = event.target.dataset.url
    Rails.ajax({
      url: url,
      type: 'GET',
      success: function(response) {
        const html1 = document.getElementById("finder_results")
        const html2 = document.getElementById("finder-form-mobile")
        html1.outerHTML = response.html1
        html2.outerHTML = response.html2
      },
      error: function(response) {
        console.log('error')
      }
    })
  }

  submitForm() {
    this.formTarget.submit()
  }

  submitAjaxForm() {
    Rails.fire(this.formTarget, 'submit');
  }

  replaceSearchResults(event) {
    const url = event.target.dataset.searchUrl
    const container = event.target.dataset.resultsContainer
  }

  clickCheckbox(event) {
    const id = event.target.dataset.checkboxId
    const checkbox = document.getElementById(id)
    if (checkbox) {
      checkbox.click()
    }
  }
}
