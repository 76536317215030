// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  createEvent() {
    const action = event.target.dataset.eventAction
    const category = event.target.dataset.eventCategory
    const label = event.target.dataset.eventLabel

    // DEBUG
    // console.log("action", action)
    // console.log("category", category)
    // console.log("label", label)

    const result = gtag('event', action, { 'event_category': category, 'event_label': label })

    // DEBUG
    // event.preventDefault()
  }
}
